<template>
  <div class="login">
    <h2 class="typography-h2">
      <!-- Onderhoud -->
      <n-html :text="$t('error.maintenance.title')" />
    </h2>
    <div>
      <p class="typography-p-regular">
        <!-- De pagina is op dit moment in onderhoud. Voor vragen kunt u terecht bij onderstaande: -->
        <n-html :text="$t('error.maintenance.subtitle')" />
      </p>
      <p class="typography-p-small">
        <n-icon class="icon" small name="ServicePhone"></n-icon> NL: 010 - 206
        86 86
      </p>
      <p class="typography-p-small">
        <n-icon class="icon" small name="ServicePhone"></n-icon> BE: 078 - 48 37
        93
      </p>
      <p class="typography-p-small">
        <n-icon class="icon" small name="Mail"></n-icon>
        <a
          class="typography-a-underline typography-a-not-bold"
          href="mailto:info@harmony.nl"
          >info@harmony.nl</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "maintenance"
};
</script>

<style lang="scss">
@import "../../theme/variables";

.icon {
  margin-right: 10px;
}
</style>
